.gradient {
   background: linear-gradient(
    to right,
    theme(colors.quartery.100) 10%,
    theme(colors.smokey.100) 10%
  );
}

.gradient2 {
   background: linear-gradient(
    to right,
    theme(colors.smokey.100) 90%,
    theme(colors.quartery.100) 90%
  );
}

.whiteArrow {
  transform: translate(-1.42109e-14px, -3.55271e-15px) rotateX(180deg)

}
